<template>
  <v-flex>
    <p class="disclaimer-text">
      I WAIVE ANY PROVISION OF LAW TO THE CONTRARY AND HEREBY AUTHORIZE CRAWFORD & COMPANY OR THEIR
      REPRESENTATIVES TO FURNISH TO ANY HOSPITAL, PHYSICIAN OR OTHER PERSON WHO HAS ATTENDED ME, AND
      MY INSURANCE CARRIER, ANY AND ALL INFORMATION WITH RESPECT TO THE ACCIDENTAL INJURY FOR WHICH
      I AM CLAIMING INSURANCE BENEFITS.
    </p>
    <p class="disclaimer-text">
      I WAIVE ANY PROVISION OF LAW TO THE CONTRARY AND HEREBY AUTHORIZE ANY HOSPITAL, PHYSICIAN OR
      OTHER PERSON WHO HAS ATTENDED ME, AND MY INSURANCE CARRIER OR EMPLOYER, TO FURNISH CRAWFORD &
      COMPANY, OR THEIR REPRESENTATIVES ANY AND ALL INFORMATION WITH RESPECT TO ANY SICKNESS OR
      INJURY, MEDICAL HISTORY, CONSULTATION, PRESCRIPTIONS, OR TREATMENT, AND COPIES OF ALL
      HOSPITAL, MEDICAL, OR INSURANCE RECORDS INCLUDING, BUT NOT LIMITED TO, INFORMATION REGARDING
      OTHER INSURANCE COVERAGES. I AGREE THAT A PHOTOCOPY OF THIS AUTHORIZATION SHALL BE CONSIDERED
      AS EFFECTIVE AS THE ORIGINAL.
    </p>
    <p class="disclaimer-text">
      I UNDERSTAND THIS AUTHORIZATION IS NECESSARY TO FACILITATE THE OBTAINING AND PROVIDING OF
      INFORMATION NEEDED TO QUICKLY PROCESS MY CLAIM.
    </p>
    <p class="disclaimer-text disclaimer-text--red">
      I CERTIFY THAT THE ABOVE INFORMATION IS TRUE AND ACCURATE TO THE BEST OF MY KNOWLEDGE AND
      BELIEF. I FURTHERMORE UNDERSTAND THAT OMISSION OF REQUESTED INFORMATION OR FRAUDULENT
      STATEMENTS CAN BE A CRIME.
    </p>
  </v-flex>
</template>

<script>
export default {
  name: 'FormWaiverDisclaimerCrawford',
};
</script>

<style lang="scss" scoped>
.disclaimer-text {
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 17px;
  letter-spacing: 1px;
  color: #464646;
  &--red {
    color: #9a2d2e;
  }
}
</style>
